<template>
  <content-box title="Team Store" class="col-md-7 col-xl-5">
    <h3 class="text-center">The team store is now OPEN!!</h3>
    <div class="my-4">
      <p>
        Don't miss out on your opportunity to get the latest team gear and
        apparel!
      </p>
      <p>
        All items include the Haverhill Hammers logo. Perfect for your favorite
        hammer to wear during practice or when we're on the road competing in
        tournaments.
      </p>
      <p>
        This gear isn't just for your little hammer. This is a great way for the
        entire family to show their support, and our team's presence, while we
        compete
      </p>
      <p>
        Offered through
        <a href="http://treasuretees.com" target="_blank">
          Treasure Tees
          <font-awesome-icon
            :icon="['fas', 'external-link-alt']"
            class=""
            size="xs"
          /> </a
        >, a portion of each sale will be donated back to our program!
      </p>
      <p>Happy shopping!</p>
    </div>

    <team-store-button />
  </content-box>

  <!--  <div>-->
  <!--    <div v-if="loading" class="d-flex justify-content-center mt-5">-->
  <!--      <div-->
  <!--        class="mt-5 spinner-border text-secondary"-->
  <!--        style="width: 5rem; height: 5rem;"-->
  <!--        role="status"-->
  <!--      >-->
  <!--        <span class="sr-only">Loading...</span>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <iframe-->
  <!--      v-show="!loading"-->
  <!--      id="team-store"-->
  <!--      class="col-12 position-relative my-3 border-0"-->
  <!--      src="http://www.treasuretees.com/haverhill_hammers_wrestling/shop/home"-->
  <!--      @load="loaded"-->
  <!--    ></iframe>-->
  <!--  </div>-->
</template>

<script>
import teamStoreButton from '@/components/teamStoreButton'
import contentBox from '@/components/contentBox'

export default {
  name: 'teamStore',
  components: {
    contentBox,
    teamStoreButton,
  },
  data() {
    return {
      loading: true,
    }
  },

  methods: {
    loaded() {
      this.loading = false
    },
  },
}
</script>

<style scoped></style>
